body {
    margin: 0;
}

.text {
    margin-left: 5vw;
}

h1 {
    margin: 0;
    font-weight: 400;
    font-size: 8.3rem;
    line-height: 8.7rem;
    position: relative;
}

h1 .letter {
    display: inline-block;
    transition: 0.3s;
}

.home {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}

p {
    font-size: 1.6rem;
    letter-spacing: 4px;
    margin-top: 3rem;
    display: block;
    color: #8d8d8d;
}

.text {
    position: relative;
}

.letter:hover {
    color: black;
    font-size: 8.5rem;
    transform: translateY(-10px);
}
.letterdif {
    color: black;
    display: inline-block;
    transition: 0.3s;
}
.letterdif:hover{
    color: rgb(122, 95, 95);
    font-size: 8.5rem;
    transform: translateY(-10px);
}
.letterhi {
    color: rgb(221, 176, 118);
    display: inline-block;
    transition: 0.3s;
    
}
.letterhi:hover{
    color: rgb(122, 95, 95);
    font-size: 8.5rem;
    transform: translateY(-10px);
}
.mainrole:hover {
    color: rgb(255, 255, 255);
    transform: translateY(-10px);
}
.x {
    position: absolute;
    top: 10vh;
    left: 50vw;

}
.x,
.y {
    width: 100px;
    height: 100px;
}

.x {
    animation: x 13s linear infinite alternate;
}

.y {
    animation: y 7s linear infinite alternate;
}

.y:hover {
    width: 20rem;
    height: 15rem
}

@keyframes x {
    100% {
        transform: translateX(45rem)
    }
}

@keyframes y {
    100% {
        transform: translateY(30rem);
    }
}


.contact>span::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}

.contact>span:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.contact>span::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: hsl(200 100% 80%);
    z-index: -1;
    transition: transform .3s ease;
}

.contact>span {
    color: rgb(0, 0, 0);
    position: relative;
    font-size: 2rem;
}



@media (orientation: landscape) {
    body {
        grid-auto-flow: column;
    }
}
@media (max-width: 900px) {
    .x {
        position: relative;
        top: 10vh;
        left: 50vw;
    
    }
    h1 {
        margin: 0;
        font-weight: 400;
        font-size: 3.3rem;
        line-height: 5.7rem;
        position: relative;
    }
    p {
        font-size: 1rem;
        letter-spacing: 4px;
        margin-top: 3rem;
        display: block;
        color: #8d8d8d;
    }
    .contact>span {
        color: rgb(0, 0, 0);
        position: relative;
        font-size: 1rem;
    }
}