.nav {
    background: linear-gradient(to top, rgb(226, 224, 221) 0%, tan 100%);
    width: 11rem;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    border-right: 4px solid black;
}

.top-a {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.name {
    color: rgb(0, 0, 0);
    font-size: 2.2rem;
    font-family: 'Festive', cursive;
    padding-bottom: 0.5vh;
}

.role {
    color: rgb(0, 0, 0);
    font-size: 1rem;
    padding-bottom: 3vh;
}

.profilepicture {
    width: 9.2vw;
    height: 110px;
}

a {
    text-decoration: none;
}

.middle {
    width: 100%;
    margin-top: 55px;
}

.middle .links {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 1.5rem;
    color: #909096;
    line-height: 3.2rem;
    border-bottom: 1px solid #282828;
    margin: -1px 0 -1px 0
}

.linkedin {
    height: 35px;
}

.github {
    height: 35px;
}



.middle {
    border-top: 1px solid #282828;
}

.socialMediaIcon {
    padding-bottom: 30px;
}
@media (max-width: 900px) {
    .nav {
        align-items: center;
        position: fixed;
        z-index: -5;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        transition: opacity 0s 0.4s, z-index 0s 0.7s;
    }
}