.projectPic {
    height: 30vh;
    width: 30vw;
}
.projectsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7vh;
}