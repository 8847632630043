body {
    font-size: 1.6rem;
    line-height: 1.5;
    background: linear-gradient(to left, rgb(145, 122, 93) 0%, rgb(71, 69, 65) 100%);
    display: block;
    min-width: 320px;
    color: #fff;
    padding-left: 13rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
@media (max-width: 900px) {
    body {
        font-size: 1rem;
        line-height: 1.3;
        background: linear-gradient(to left, rgb(145, 122, 93) 0%, rgb(71, 69, 65) 100%);
        display: block;
        color: #fff;
        padding-left: 0rem;
        overflow-y: hidden;
        overflow-x: hidden;
    }
}
